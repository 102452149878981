export const RESET_APP = "RESET_APP";
export const SAVE_SEASON = "SAVE_SEASON";
export const SECTION_NAME = "SECTION_NAME";
export const UPDATES_SECTION = "UPDATES_SECTION";
export const TRADERPRCNT = "TRADERPRCNT";
export const DISTRIBUTERPRCENT = "DISTRIBUTERPRCENT";
export const VAT = "VAT";



